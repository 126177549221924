import React, { Component, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useImperativeHandle } from 'react';
import { auth as authService } from 'services';
import PropTypes from 'prop-types';

const formatTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const minutesString = `0${minutes}`.slice(-2);
  const secondsString = `0${remainingSeconds}`.slice(-2);

  return `${minutesString}:${secondsString}`;
};

class Paragraph extends Component {
  static propTypes = { content: PropTypes.string };
  static defaultProps = { content: '' };
  render = () => <p className={'pwdl-scanqr-message'} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
}

const LoginWithPasswordlessModal = (props, ref) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [isTrigger, setTrigger] = useState(false);
  const [count, setCount] = useState(0);
  const [qrCode, setQrCode] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isTriggerInvoke, setTriggerInvoke] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const timerCountdown = useRef();
  const timerCallRequest = useRef();

  useImperativeHandle(ref, () => {
    return {
      handleToggle: onToggle
    };
  });

  useEffect(() => {
    if (isTriggerInvoke) generateQRCode();
  }, [isTriggerInvoke]);

  useEffect(() => {
    if (isTrigger) {
      timerCountdown.current = setInterval(() => {
        setCount(prev => prev - 1);
      }, 1000);

      timerCallRequest.current = setInterval(() => {
        callRequest();
      }, 2000);
    }

    return () => {
      clearInterval(timerCountdown.current);
      clearInterval(timerCallRequest.current);
    };
  }, [isTrigger]);

  useEffect(() => {
    if (count === 0) {
      setOpen(false);
      clearInterval(timerCountdown.current);
      clearInterval(timerCallRequest.current);
      setTrigger(false);
    }
  }, [count]);

  const onToggle = () => {
    if (!isOpen) setCount(180);

    setOpen(prev => !prev);
    setTriggerInvoke(true);
  };

  const generateQRCode = async () => {
    try {
      if (showSuccessMessage) {
        setShowSuccessMessage(false);
      }
      const QR_URL_FORMAT = process.env.REACT_APP_TCI_IAM_URL_PASSWORDLESS + '/auth?sessionId=';
      const result = await authService.getQRCode();
      const url = await QRCode.toDataURL(QR_URL_FORMAT + result);
      setSessionId(result);
      setTrigger(true);
      setTriggerInvoke(false);
      setQrCode(url);
      setHasError(false);
    } catch (e) {
      setSessionId('');
      setHasError(true);
      setTriggerInvoke(false);
      setQrCode('');
    }
  };

  const callRequest = async () => {
    if (count % 3 === 0 && count !== 0 && sessionId) {
      await authService
        .getStatusQRCode(sessionId)
        .then(res => {
          if (res) {
            if (res.status === 'DONE') {
              clearInterval(timerCountdown.current);
              clearInterval(timerCallRequest.current);
              setTrigger(false);
              setOpen(false);
              props.signIn(res);
            } else if (res.status === 'IN-PROGRESS') {
              setShowSuccessMessage(true);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    clearInterval(timerCountdown.current);
    clearInterval(timerCallRequest.current);
    setTrigger(false);
    localStorage.setItem('sessionQR', '');
  };

  return (
    <Modal
      size="lg"
      style={{ maxWidth: '700px', width: '100%' }}
      isOpen={isOpen}
      className="reactstrap-confirm passwordless"
      zIndex={2050}
      centered
    >
      <ModalHeader>
        <div className="md-header" style={{ width: '98%' }}>
          <img
            className="img-fluid"
            style={{ maxHeight: '45px', width: 30 }}
            src={require('assets/img/passwordless/logo_window.png')}
            alt="logo"
          />
          <p>{t('passwordless.title')}</p>
        </div>
      </ModalHeader>
      <ModalBody>
        {qrCode && (
          <>
            <img style={{ width: '45%' }} src={qrCode} alt="qr-code" />
            <p>{formatTime(count)}</p>
            {showSuccessMessage && <Paragraph content={t('passwordless.scan.qr.successfully')} />}
          </>
        )}
        {hasError && (
          <>
            <p>{t('passwordless.failed')}</p>
            <p>{t('passwordless.tryAgain')}</p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-info btn-cons" type="button" onClick={handleClose}>
          {t('common.button.cancel')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default React.forwardRef(LoginWithPasswordlessModal);
