export const randomString = len =>
  Array(len)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map(function(x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');

export const validURL = str => {
  try {
    if (!str.startsWith('http://') && !str.startsWith('https://')) return false;

    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

export const compareString = (a, b) => {
  const _a = a ? a.toLowerCase() : '';
  const _b = b ? b.toLowerCase() : '';
  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
};

/* convert string from full to half width
 *		１２３ to 123
 *   'space' full to half width
 */
export const toHalfWidth = str => {
  const value = str
    .replace(/[\uff01-\uff5e]/g, ch => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
    .replace(/\u3000/g, '\u0020')
    .replace(/[ー−]/g, '\u002D');
  return value;
};

export const getEnvName = env => {
  let envName = 'Local';
  switch (env) {
    case 'development':
      envName = 'Testing';
      break;
    case 'nightly':
      envName = 'Alpha';
      break;
    case 'prod':
    case 'production':
      envName = 'Production';
      break;
    case 'dev':
      envName = 'Eval';
      break;
    case 'staging':
      envName = 'Beta';
      break;
    default:
      break;
  }
  return envName;
};

export const encodeBase64 = str => {
  return Buffer.from(str).toString('base64');
};

export const decodeBase64 = str => {
  try {
    if (str) {
      return Buffer.from(str, 'base64').toString();
    }
  } catch (error) {
    console.error('decodeBase64 -> error -> ', error);
  }
  return str;
};

export const checkIsMobile = (hasCheckIsMobile = true) => {
  const userAgent = navigator.userAgent || window.opera;
  const touchScreen = 'ontouchstart' in document.documentElement;
  if (localStorage.getItem('isMobile')) {
    return true;
  }

  if (!hasCheckIsMobile) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent) && touchScreen;
  }
  return false;
};

export const isTablet = () => {
  const userAgentCheck = /iPad|Android(?!.*Mobile)|Tablet|PlayBook|Silk/i.test(navigator.userAgent);
  const screenCheck = window.matchMedia('(min-width: 600px) and (max-width: 1024px)').matches;
  const touchCheck = navigator.maxTouchPoints > 1;
  return userAgentCheck || (screenCheck && touchCheck);
};

const getBrowserName = userAgent => {
  let browserName = '';
  // Get browser name
  switch (true) {
    case userAgent.indexOf('Edg') > -1:
      browserName = 'Microsoft Edge';
      break;
    case userAgent.indexOf('Chrome') > -1:
      browserName = 'Google Chrome';
      break;
    case userAgent.indexOf('Safari') > -1:
      browserName = 'Safari';
      break;
    case userAgent.indexOf('Firefox') > -1:
      browserName = 'Mozilla Firefox';
      break;
    default:
      browserName = 'Unknown';
      break;
  }
  return browserName;
};

const getBrowserVersion = userAgent => {
  let browserVersion = '';
  // Get browser version
  let match = userAgent.match(/(chrome|safari|firefox|edge(?=\/))\/?\s*(\d+)/i) || [];
  let term;
  if (match[1] === 'Chrome') {
    term = userAgent.match(/\b(Edge)\/(\d+)/);
    if (term != null) return term.slice(1).join(' ');
  }
  match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((term = userAgent.match(/version\/(\d+)/i)) != null) match.splice(1, 1, term[1]);
  browserVersion = match.join(' ');
  return browserVersion;
};

const getOsVersion = userAgent => {
  let osVersion = '';
  // Check for operating system version
  switch (true) {
    case userAgent.indexOf('Windows NT') > -1:
      osVersion = userAgent.substring(
        userAgent.indexOf('Windows NT') + 11,
        userAgent.indexOf(';', userAgent.indexOf('Windows NT'))
      );
      break;
    case userAgent.indexOf('Mac OS') > -1:
      osVersion = userAgent.substring(
        userAgent.indexOf('Mac OS') + 7,
        userAgent.indexOf(')', userAgent.indexOf('Mac OS'))
      );
      break;
    case userAgent.indexOf('Linux') > -1:
      osVersion = 'Unknown';
      break;
    case userAgent.indexOf('Android') > -1:
      osVersion = userAgent.substring(
        userAgent.indexOf('Android') + 8,
        userAgent.indexOf(';', userAgent.indexOf('Android'))
      );
      break;
    case userAgent.indexOf('iOS') > -1:
      osVersion = userAgent.substring(userAgent.indexOf('OS') + 3, userAgent.indexOf(')', userAgent.indexOf('OS')));
      break;
    default:
      osVersion = 'Unknown';
      break;
  }
  return osVersion;
};

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  const browserName = getBrowserName(userAgent);
  const browserVersion = getBrowserVersion(userAgent);
  const osVersion = getOsVersion(userAgent);
  return { browserName, browserVersion, osVersion };
};

export const getCurrentTimestamp = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

  const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

  return timestamp;
};
