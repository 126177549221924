import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import { Copyright } from 'components';
import i18n from 'i18n';
import { checkIsMobile } from 'core/helpers';

class Footer extends PureComponent {
  static propTypes = {
    i18n: PropTypes.object,
    t: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        {
          value: 'en_US',
          label: 'English'
        },
        {
          value: 'ja_JP',
          label: '日本語'
        },
        {
          value: 'zh_TW',
          label: '繁體中文'
        }
      ]
    };
  }
  changeLanguage = res => {
    const i18next = this.props.i18n;
    const value = res.value;
    localStorage.setItem('sysLanguage', value);
    i18next.changeLanguage(value);
  };
  render() {
    const { t } = this.props;
    const lang = localStorage.getItem('sysLanguage') || 'en_US';
    const langValue = this.state.languages.filter(a => a.value === lang)[0];
    return (
      <div className="container-fluid container-fixed-lg footer">
        <div className={`${checkIsMobile() ? 'copyright-mobile' : ''} copyright sm-text-center row`}>
          <div className="col-12 col-md-8 copyright-centering">
            <Copyright
              className={`small no-margin pull-left sm-pull-reset ${checkIsMobile() ? 'copyright-nowrap' : ''}`}
            />
          </div>
          {!checkIsMobile() && (
            <div className="col-12 col-md-4">
              <div className="langs" style={{ width: '180px' }}>
                <Select
                  value={langValue}
                  onChange={this.changeLanguage}
                  options={this.state.languages}
                  classNamePrefix="react-select"
                  menuPlacement="top"
                  noOptionsMessage={() => i18n.t('common.label.no.option')}
                  styles={{
                    menu: provided => ({ ...provided, zIndex: 9 })
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Footer);
